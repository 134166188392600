var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// On load resize and scroll
$('document').ready(function(){
  $(window).on('load resize scroll', function() {
    $('#menuMain').css('right', $(window).width() - $('.mainCallToActionButtons').offset().left - 15);
  });
})

// Disallow access to homepage if not logged in with admin account - commented out as this caused issues with site going live
if ( !$('.adminBar').length && window.location.pathname == '/' ) {
  window.location.href = "/pages/appeal";
} else {
  $('body').addClass('loaded');
}

$('.mainLogo').attr('href', 'https://kintsugihope.com/');